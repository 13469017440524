import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom'
import {motion} from 'framer-motion';

const logoInteraction = {
  hover : {
    scale: 1.09,
    transition : {duration: 0.4, ease: [.43,.13,.23,.96] },
  },
  beforeTap : {
    scale: 1,
  },
  afterTap : {
    scale: [1.09,0.7,0.7,3],
    rotate: [0,-90],
    opacity: [1,1,0.9,0],
    transition : { duration: 2.2, ease:[.6,.01,-.05,0.96] },

  },
}

export default function Home() {

  return (
    <div>
      <motion.div className='home'
      >
        <div className="home-logo">
          <Link to="/about">
            <motion.img
              src="images/sideways3.jpg" 
              alt="" 
              whileHover="hover" 
              variants={logoInteraction}
              exit="afterTap"
            />
          </Link>
        </div>
      </motion.div>
    </div>
  );
}
