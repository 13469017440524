import { AnimatePresence } from "framer-motion";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/App.scss";
import Scroll from "./components/SmoothScroll";
import Navbar from "./components/navbar/Navbar";
import Home from "./components/home/Home";
import CatalogueHome from "./components/catalogue/cHome";
import { About } from "./components/about/About";

function App() {
  return (
    <Router>
      <Route
        render={({ location }) => (
          <div className="App">
            <Scroll />
            <Navbar />
            <AnimatePresence initial={false} exitBeforeEnter>
              <Switch location={location} key={location.pathname}>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/about">
                  <About />
                </Route>
                <Route path="/catalogue">
                  <CatalogueHome />
                </Route>
              </Switch>
            </AnimatePresence>
          </div>
        )}
      />
    </Router>
  );
}

export default App;
