import React from 'react';
import './About.css';
import Footer from '../footer/Footer';
import {motion} from 'framer-motion';

// NOTE: This is unused @ashmit should it be used somewhere?
const parent = {
  initial : {
    opacity : 0,
    scale : 1
  },

  animate : {
    opacity : 1,
    scale: 1
  },
}

const transition = {
  duration: 1.2 , ease:[.6,.01,-.05,0.96] 
}


export const About = () => {

  return (
    <motion.div 
      className='about'

    >
      <motion.div
        className='manifesto'
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={transition}

      >
          <div className='title'>
            <h2>Manifesto</h2>
          </div>
          <div className='description'>
            <p>We are SIDEWAYS. We are underground. </p>
            <p>Our community is made up of a diverse group of artists, musicians, and collectors building a
              supportive and collaborative space within Web3 and NFTs. Our mission is defined by our
              dedication to add tangible and intangible value to our members.</p>
            <p>Our primary priority is the V!BE; maintaining a safe, healthy, and uplifting environment with
              active contributors. We thrive on fostering genuine connections with aligned values; absent of
              judgment or competitive attitudes. Preservation of this environment is critical to maximize our
              power as a collective, and to ensure our growth is optimized with fairness and mutual benefit
              for all.</p>
            <p>We have purposefully adopted a non-formulaic approach to the DAO (Decentralized Autonomous Organization) to allow for evolution and
              input from all members. There are endless opportunities to build the roadmap in real time with
              dedicated contributors, leading to the common goal of adding value to the community.</p>
            <p>Our collective force will strengthen both individual and collaborative ventures, creating a ripple
              effect into the arts community at large as a precedent of the fusion of art, technology, and
              community.
            </p>
          </div>
      </motion.div>

      <motion.div 
        className='membership'
        // animate={{opacity: reveal ? 1 : 0}}
        // initial={{opacity: 0}}
        // transition={{opacity: {duration:0.2}}}
      >
        <div className="title">
          <h2>Membership</h2>
        </div>
        <div className="description">
          <p>New members are added by a consensus vote following an <a target="_blank" rel="noreferrer" href="https://forms.gle/pzAnNFwBSNHTa71F9">
            application submission</a>. An induction
            process is finalized by acquiring the sidewaysDAO token - <a target="_blank" rel="noreferrer" href="https://opensea.io/assets/0x9a964ad2e578fd4647631c6f7a5a7f37b742f12d/2">
              a SKELETON KEY</a> - at a fixed price.</p>
        </div>
      </motion.div>

      <motion.div 
        className='treasury'
        
      >
        <div className='title'>
          <h2>Treasury</h2>
        </div>
        <div className='description'>
          <p>DAO wallet address:<br></br>
            sideways.eth | <a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0x2290917c4A1a2F2b9d8C0Af2a381D6FdA1B031b4"><span className="addr-text">0x2290917c4A1a2F2b9d8C0Af2a381D6FdA1B031b4</span></a>
          </p>

          <p>DAO contract address:<br></br>
            <a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0x9a964ad2e578fd4647631c6f7a5a7f37b742f12d"><span className="addr-text">0x9a964ad2e578fd4647631c6f7a5a7f37b742f12d</span></a>
          </p>

          <p>The treasury is governed by DAO founding members:
            <ul>
              <li><a target="_blank" rel="noreferrer" href="https://twitter.com/oOLatchOo">La+ch</a></li>
              <li><a target="_blank" rel="noreferrer" href="https://twitter.com/unknownfunkhero">Unkfunk</a></li>
              <li><a target="_blank" rel="noreferrer" href="https://twitter.com/iamwhitelights">iamwhitelights</a></li>
              <li><a target="_blank" rel="noreferrer" href="https://twitter.com/Natirupe">natirupe</a></li>
              <li><a target="_blank" rel="noreferrer" href="https://twitter.com/WidmerLuiz">widmer</a></li>
            </ul>
          </p>
          <div className='outro'>
            <p>Through the work completed by sidewaysDAO sub-groups, treasury funds will be turned into outcomes
              that are aligned with the mission and goals.</p>
            <p>These tasks will ultimately be achieved through the democratic process of submission and approval of
              proposals, which will be instrumental to the completion of tasks and projects.
            </p>
          </div>
        </div>
      </motion.div>

      <motion.div 
        className='about-footer'
        
      >
        <Footer />
      </motion.div>
    </motion.div>
  );
};
