import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { FaTwitter, FaDiscord } from "react-icons/fa";

export default function Footer() {
    return (
        <footer className='footer'>
            <div className='l-footer'>
                <img src='/images/sideways_girl_circle.png'></img>
            </div>

            <ul className='r-footer'>
                <h2>Mail us at -</h2>
                <p><a href="mailto: sidewaysdao@gmail.com">sidewaysdao@gmail.com</a></p>
                <li>
                    <ul className='socials'>

                        <li><a href="https://twitter.com/daosideways" target="_blank">Twitter</a> | &nbsp;
                        <a href="https://discord.gg/bxQDCXQcX3" target="_blank">Discord</a></li>

                        <div className='socials-icon'>
                            <a href="https://twitter.com/daosideways" target="_blank"><FaTwitter /></a>
                        </div>
                        <div className='socials-icon'>
                            <a href="https://discord.gg/bxQDCXQcX3" target="_blank"><FaDiscord /></a>
                        </div>
                    </ul>
                </li>
            </ul>
        </footer>
    );
}
