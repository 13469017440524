import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const transition = { duration: 0.4, ease: [0.43, 0.13, 0.23, 0.96] };

export default function Navbar() {
  return (
    <div className="navbar">
      <div className="nav-item home-button">
        <Link to="/">Home</Link>
      </div>
      {/* <div className="nav-item auction-button">
        <a target="_blank" href="https://auction.sidewaysdao.xyz">
          Current Auction
        </a>
      </div> */}
      <div className="nav-item about-button">
        <Link to="/about#">
          <motion.img
            whileHover={{ scale: 1.3 }}
            transition={transition}
            src="/images/handwss.png"
            alt=""
          />
        </Link>
      </div>
    </div>
  );
}
